


































































import {Component, Vue} from 'vue-property-decorator';
import HeaderBar from "@/components/HeaderBar.vue";
import {actionStringWork, Category, IWork} from "@/store/work";
import {Action} from "vuex-class";
import {category} from "@/Types/Types";
import Loader from "@/components/loader.vue";
import FooterBar from "@/components/FooterBar.vue";
import FooterMain from "@/components/FooterMain.vue";


@Component({
  components: {FooterMain, FooterBar, Loader, HeaderBar},
})

export default class About extends Vue {
  @Action(actionStringWork.GET_WORK) getWork: (() => Promise<IWork[]>) | undefined;





  created(): void {

  }
}

